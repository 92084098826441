<template>
  <div>
    <h1>Saison 2022</h1>
    Actus, résultats outdoor/indoor...
  </div>
</template>

<script>
export default{
  mounted(){
    this.$hideLoader();
  }
}
</script>

